<template>
  <canvas
    id="peak-budget-chart"
    width="400"
    height="100"
  />
</template>

<script>
import Chart from 'chart.js'
import annotation from 'chartjs-plugin-annotation'
import travelContents from 'mixins/travelContents'
import _ from 'lodash'
export default {
  mixins: [travelContents],
  props: {
    breakdown: {
      type: Array
    },
    budget: {
      type: Number
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    breakdown () { this.initForm() }
  },
  mounted () {
    this.initForm()
  },
  methods: {
    initForm: function () {
      if (this.chart) {
        this.chart.destroy()
      }
      const sortedBreakdown = _.sortBy(this.breakdown, c => c.total.amount).filter(c => c.label !== 'fuel' && c.total.amount !== 0)
      const chartReady = sortedBreakdown.map(b => {
        return {
          label: b.name.charAt(0).toUpperCase() + b.name.substr(1),
          amount: b.total.text,
          data: [b.total.amount / 100],
          backgroundColor: this.getTravelContentHex(b.name)
        }
      })
      this.chart = new Chart(this.$el, {
        plugins: [annotation],
        type: 'horizontalBar',
        label: 'Spend',
        data: {
          datasets: chartReady
        },
        options: {
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              ticks: {
                suggestedMax: this.budget ? this.budget / 100 : 100
              }
            }],
            yAxes: [{ stacked: true }]
          },
          legend: {
            display: false
          },
          tooltips: {
            mode: 'nearest',
            displayColors: false,
            backgroundColor: 'white',
            bodyFontColor: 'black',
            callbacks: {
              label (t, d) {
                var label = d.datasets[t.datasetIndex].label
                var amount = d.datasets[t.datasetIndex].amount

                return label + ': ' + amount
              }
            }
          },
          annotation: {
            drawTime: 'afterDatasetsDraw',
            annotations: [{
              type: 'line',
              id: 'vline',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: this.budget ? this.budget / 100 : null,
              borderColor: 'red',
              borderWidth: 2
              // label: {
              //   enabled: true,
              //   position: 'center',
              //   content: 'Budget'
              // }
            }]
          }
        }
      })
    }
  }
}
</script>
