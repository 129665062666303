<template>
  <m-infinite-activity
    :getter="load"
    height="calc(100vh - 255px)"
  />
</template>

<script>
import { activities as getUserActivity } from 'api/members'

export default {
  methods: {
    async load (filters) {
      return getUserActivity(this.$route.params.username, filters).then((res) => {
        return res
      })
    }
  }
}
</script>
