<template>
  <q-drawer
    v-model="ui.quasar.sides.right"
    overlay
    side="right"
  >
    <div v-if="individual">
      <div
        class="bg-primary top-sheet shadow-1"
      >
        <q-btn
          round
          flat
          color="white"
          size="sm"
          icon="close"
          style="position: absolute; top: 8px; right: 8px"
          @click="close"
        />
        <div class="row q-col-gutter-xs">
          <div class="col-sm-2">
            <m-image
              :src="individual.picture"
              class="avatar"
            />
          </div>
          <div class="col-sm-10">
            <h5 class="text-white">
              {{ individual.display_name }}
            </h5>
            <p class="text-white last-active">
              Last active: {{ individual.last_seen_at ? lastSeen : 'Never' }}
            </p>
          </div>
        </div>
      </div>
      <div class="peak-body">
        <div class="budget">
          <q-item-label v-if="individual.monthly_budget.amount" header>
            Budget - {{ individual.monthly_budget.text }}
          </q-item-label>
          <q-item-label v-else header>
            Breakdown - No Budget Set
          </q-item-label>
          <peak-budget-chart
            :key="individual.reference"
            :breakdown="individual.breakdown"
            :budget="individual.monthly_budget.amount"
          />
          <sub
            v-if="overbudget"
            class="text-red"
          >
            Currently £{{ spend.subtract(budget).toString() }} over budget
          </sub>
          <sub
            v-else
            class="text-green"
          >
            Currently £{{ budget.subtract(spend).toString() }} under budget
          </sub>
        </div>
        <q-list
          no-border
          highlight
          class="recent-journeys"
        >
          <q-item-label header>
            Recent Journeys
          </q-item-label>
          <q-item
            v-for="j in individual.journeys"
            :key="j.reference"
            :to="`journeys/${j.reference}`"
          >
            <q-item-section avatar>
              <q-avatar
                :icon="getTravelContentIcon(j.type)"
                :color="getTravelContentColor(j.type)"
                inverted
                text-color="white"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="ellipsis-2-lines">
                {{ j.name }}
              </q-item-label>
              <q-item-label caption>
                {{ toShortDate(j.date.date) }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <m-empty-state
            v-if="individual.journeys.length === 0"
            :style="{margin: 'auto', padding: 0}"
            :font-size="20"
            icon="map"
            size="60px"
          >
            No Journeys found
          </m-empty-state>
        </q-list>
        <q-list
          no-border
          highlight
          class="recent-bookings"
        >
          <q-item-label header>
            Recent Bookings
          </q-item-label>
          <q-item
            v-for="b in individual.bookings"
            :key="b.reference"
            :to="`bookings/${b.reference}`"
          >
            <q-item-section avatar>
              <q-avatar
                :icon="getTravelContentIcon(b.type)"
                :color="getTravelContentColor(b.type)"
                inverted
                text-color="white"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label label class="ellipsis-2-lines">
                {{ b.name }}
              </q-item-label>
              <q-item-label caption>
                {{ toShortDateTime(b.booked_at.date) }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <m-empty-state
            v-if="individual.bookings.length === 0"
            :style="{margin: 'auto', padding: 0}"
            :font-size="20"
            icon="receipt"
            size="60px"
          >
            No Bookings found
          </m-empty-state>
        </q-list>
        <div class="actions">
          <user-meta-actions
            :round="false"
            :individual="individual"
            color="black"
          />
          <note-dialog
            v-if="canCreateNote"
            :create="create"
          />
          <q-btn
            color="primary"
            class="btn-view bg-light-grey"
            label="View User"
            @click="view"
          />
        </div>
      </div>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import travelContents from 'mixins/travelContents'
import userMetaActions from '../individual-meta-actions'
import peakBudgetChart from './peak-budget-chart'
import noteDialog from '@/MResourceNotes/noteDialog'
import { addNote } from 'api/individuals'
import date from 'utils/date-time'
import Money from 'js-money'
export default {
  components: {
    userMetaActions,
    peakBudgetChart,
    noteDialog
  },
  mixins: [
    travelContents
  ],
  computed: {
    ...mapGetters({
      ui: 'ui'
    }),
    budget () {
      return new Money(this.individual.monthly_budget.amount, Money.GBP)
    },
    spend () {
      return new Money(this.individual.spend.amount, Money.GBP)
    },
    overbudget () {
      return this.spend.greaterThan(this.budget)
    },
    underbudget () {
      return this.individual.spend.amount < this.individual.monthly_budget.amount
    },
    individual () {
      return this.$store.getters['individuals/getSelectedUser'] || null
    },
    lastSeen () {
      return this.individual.last_seen_at ? date.toShortDate(this.individual.last_seen_at.date) : null
    },
    canCreateNote () {
      return this.$store.getters.hasPermission('users.notes.store')
    }
  },
  methods: {
    toShortDate: date.toShortDate,
    toShortDateTime: date.toShortDateTime,
    view () {
      this.$router.push({ name: 'individuals-view', params: { username: this.individual.username, tab: 'profile' } })
    },
    create (content) {
      return addNote(this.individual.username, content).then(note => {
        this.$q.notify({ color: 'positive', icon: 'mdi-check', position: 'bottom', message: 'Note added!' })
      })
    },
    close () {
      this.$store.dispatch('toggleRightSidebar')
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-sheet
  padding 0.75rem
  position relative
  z-index 100
.avatar
  width 36px
  height 36px
h5
  font-size 18px
  line-height 1.2
.last-active
  font-size 14px
.actions
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin-bottom 15px
.btn-view
  margin-top 15px
  width 182px
.peak-body
  height calc(100vh - 126px)
  overflow auto

.total-budget
  height 25px
  width 100%
  background lightgrey
.content-spend
  height 25px
  display inline-block
sub
  margin-left 16px
</style>
